import React from "react";
import * as classes from "./ImageCarouselController.module.scss";
import ControlButton from "../CardsCarousel/ControlButton/ControlButton";
import { ImageCarousel } from "./ImageCarousel/ImageCarousel";

export default function ImageCarouselController(props) {
  const carouselLength = props.imageSet.length;
  const [active, setActive] = React.useState(1);
  const [arrowLeftDisabled, setArrowLeftDisabled] = React.useState(true);
  const [arrowRightDisabled, setArrowRightDisabled] = React.useState(false);

  function arrowClickLeft() {
    if (active === 1) return;
    const newActive = active - 1;
    setActive(newActive);
    if (newActive === 1) setArrowLeftDisabled(true);
    setArrowRightDisabled(false);
  }
  function arrowClickRight() {
    if (active === carouselLength) return;
    const newActive = active + 1;
    setActive(newActive);
    if (newActive === carouselLength) setArrowRightDisabled(true);
    setArrowLeftDisabled(false);
  }

  return (
    <div className={classes.carouselContainer}>
      <ControlButton
        clicked={arrowClickLeft}
        disabled={arrowLeftDisabled}
        variant="left"
        context="images"
      />
      <ImageCarousel active={active} move={active} imageSet={props.imageSet} />
      <ControlButton
        clicked={arrowClickRight}
        disabled={arrowRightDisabled}
        variant="right"
        context="images"
      />
    </div>
  );
}
