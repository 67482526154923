import React from "react";

import * as classes from "../SCSS/content.module.scss";
import * as styles from "../SCSS/about-me.module.scss";

import ImageCarousel from "../components/ImageCarousel/ImageCarouselController";
import MainPageTemplate from "../components/Templates/PageTemplates/MainPageTemplate";
import { AccordionSet } from "../components/AccordionSet/AccordionSet";

import { WaveTriangle, Student, Books, DiamondsFour } from "phosphor-react";
import { graphql, useStaticQuery } from "gatsby";
import { about } from "../NavItems";

const AboutMePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulImageCarousel {
        nodes {
          images {
            gatsbyImageData
            title
          }
        }
      }
    }
  `);

  const getAge = (dateString) => {
    const ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
  };

  const { images } = data.allContentfulImageCarousel.nodes[0];

  return (
    <MainPageTemplate
      selected={about}
      pageTitle="About Me"
      pageHeading="About Me."
      pageDescription={`Hi, I'm Dan! I'm a ${getAge(
        "14 March 1993"
      )}-year-old product designer from Cork, Ireland. I'm passionate about trying to make a real difference through design and I pride myself on having the front-end skills to bring my ideas to life. 
         Previously, I was a multiple-time Irish swimming champion and represented Ireland on the world stage.`}
    >
      <section className={styles.contentSection}>
        <div className={styles.iconContainer}>
          <Books
            size={"4.8rem"}
            color="#a7a2dc"
            weight="duotone"
            className={styles.aboutIcon}
          />
          <h1 className={classes.heading2}>What I do</h1>
        </div>
        <p className={[classes.bodyText, classes.mediumSpacerBottom].join(" ")}>
          Throughout my career, I've had the opportunity to gain many skills in
          the fields of UX and product design. Here are a few that I pride
          myself on;
        </p>
        <div className={classes.bodyText}>
          <ul className={classes.listBasic}>
            <li>
              <span className={classes.heading4}>
                {" "}
                UX &amp; Interaction design
              </span>
              Using various UX methodologies and user insights to design
              solutions that solve real user problems.
            </li>
            <li>
              <span className={classes.heading4}> UX Research</span>
              Conducting different UX Research methodologies to uncover the
              goals, needs &amp; pain points that users experience.
            </li>
            <li>
              <span className={classes.heading4}>
                {" "}
                UI Design &amp; Design Systems
              </span>
              Applying visual design &amp; atomic design principles to create of
              scalable design components and visually appealing interfaces
            </li>
            <li>
              <span className={classes.heading4}>Front-end Development</span>I
              use numerous front-end technologies to bring my designs to life,
              including HTML, CSS, Javascript &amp; React.js.
            </li>
            <li>
              <span className={classes.heading4}>Accessibilty </span>
              In both design &amp; code, I'm always considering how to make my
              designs fully accessible.
            </li>
          </ul>
        </div>
      </section>

      <section className={styles.contentSection}>
        <div
          className={[styles.iconContainer, classes.smallSpacerBottom].join(
            " "
          )}
        >
          <DiamondsFour
            size={"4.8rem"}
            color="#8bcc7a"
            weight="duotone"
            className={styles.aboutIcon}
          />
          <h2 className={classes.heading2}>Work Experience</h2>
        </div>
        <div className={classes.bodyText}>
          <AccordionSet />
        </div>
      </section>
      <section className={styles.contentSection}>
        <div
          className={[styles.iconContainer, classes.smallSpacerBottom].join(
            " "
          )}
        >
          <Student
            size={"4.8rem"}
            color="#ca7acc"
            weight="duotone"
            className={styles.aboutIcon}
          />
          <h2 className={classes.heading2}>Education</h2>
        </div>

        <div className={classes.bodyText}>
          <ul className={classes.list}>
            <li>
              UI Design certification, UX Design Institute{" "}
              <span
                className={[
                  classes.smallBodyText,
                  classes.smallSpacerLeft,
                ].join(" ")}
              >
                2020-2021
              </span>
            </li>
            <li>
              Professional UX diploma, UX Design Institute{" "}
              <span
                className={[
                  classes.smallBodyText,
                  classes.smallSpacerLeft,
                ].join(" ")}
              >
                2019-2020
              </span>
            </li>
            <li>
              BSC in Design Ergonomics, Loughborough University
              <span
                className={[
                  classes.smallBodyText,
                  classes.smallSpacerLeft,
                ].join(" ")}
              >
                2012-2016
              </span>
            </li>
          </ul>
        </div>
      </section>

      <section className={styles.contentSection}>
        <div className={styles.iconContainer}>
          <WaveTriangle
            size={"4.8rem"}
            color="#a4c3ea"
            weight="duotone"
            className={styles.aboutIcon}
          />
          <h2 className={classes.heading2}>Other Interests</h2>
        </div>
        <p className={[classes.bodyText, classes.mediumSpacerBottom].join(" ")}>
          Surfing has always been a big passion of mine. I've been surfing for
          the best part of 20 years, and getting in the water and catching waves
          excites me like very little else does. Unfortunately, the swell on the
          south coast of Ireland can be a bit inconsistent, especially over the
          summer. That means that we have to brave the winter conditions.
          Surfing through the winter in Ireland isn't the most glamorous. The
          water's freezing and the conditions can be pretty harsh, but it's
          gratifying when you get it on a good day! In recent years, I've also
          enjoyed getting into mountain biking and indoor climbing. They're
          excellent ways to stay fit when the ocean is dormant.
        </p>
        <p className={[classes.bodyText, classes.mediumSpacerBottom].join(" ")}>
          Here are a few pictures from a recent winter swell — photo credit to{" "}
          <a
            href="https://www.michaelpriorphotography.net/"
            className={classes.linkText}
          >
            Michael Prior
          </a>
          .
        </p>

        <ImageCarousel imageSet={images} />
      </section>
    </MainPageTemplate>
  );
};

export default AboutMePage;
