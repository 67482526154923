import React from "react";
import { workExperienceData } from "../../workExperienceData";
import Accordion from "./Accordion/Accordion";

export function AccordionSet(props) {
  const arrayLength = workExperienceData.length;
  return (
    <div>
      {workExperienceData.map((cur, i) => (
        <Accordion
          key={i + 1}
          linkName={cur.linkName}
          linkURL={cur.linkURL}
          jobTitle={cur.jobTitle}
          jobDescription={cur.jobDescription}
          dates={cur.dates}
          firstitem={i === 0}
          lastitem={i === arrayLength - 1}
        />
      ))}
    </div>
  );
}
