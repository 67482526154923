export const workExperienceData = [
  {
    linkName: "CergenX",
    jobTitle: " : Senior Product Designer",
    linkURL: "https://www.cergenx.com",
    jobDescription:
      "Starting in July 2022, I'll be working as a senior product designer on the CergenX team. In the medical tech space, the CergenX product aims to monitor the brain activity of newborn infants to identify those most at risk of brain injuries where early intervention is crucial. My role includes developing a medical device interface design, along with designing and developing various supporting web platforms.",
    dates: "Now",
  },
  {
    linkName: "Poppulo",
    jobTitle: " : UI/UX Designer",
    linkURL: "https://www.poppulo.com",
    jobDescription:
      "I spent nearly 3 years as a UI/UX designer with Poppulo. Poppulo provides an omnichannel internal communications platform to some of the world's largest, most well-known companies and their internal communications teams. During my time at Poppulo I worked on various parts of the product including the employee mobile app, the Analytics product and the new Integrations platform.",
    dates: "2019-2022",
  },
  {
    linkName: "Fathom",
    jobTitle: " : UX Associate",
    linkURL: "https://www.fathom.pro",
    jobDescription:
      "My first role in the digital design industry was with Fathom. Fathom is an award-winning experience design agency based in Belfast that works with well-known businesses and organisations to improve their UX and service design. I worked alongside the team on multiple UX research & design projects for Fathom's clients. I gained valuable experience that set me up well for a career in design.",
    dates: "2018-2019",
  },
  {
    linkName: "",
    jobTitle: "Irish international swimmer",
    linkURL: "https://www.google.com",
    jobDescription:
      "I spent much of my previous life swimming up and down a pool. I was a multiple-time national champion & represented Ireland at several international competitions, including multiple European Championships and World University Games. In 2015 I was ranked 12th in the world in my main event, the 200m breaststroke. It didn't come easily; for years, I trained up to 30 hours a week in constant pursuit of those 'fractions of a percent' improvements.",
    dates: "2012-2017",
  },
];
