import React from "react";
import * as classes from "./Image.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Image(props) {
  let imageContainerClasses = [classes.carouselImage];
  props.active
    ? imageContainerClasses.push(classes.activeImage)
    : imageContainerClasses.push(classes.disabledImage);

  return (
    <div
      className={imageContainerClasses.join(" ")}
      style={{
        transform: `translateX(calc(-${props.move - 1}00% - ${
          (props.move - 1) * 40
        }px)) scale(${props.active ? 100 : 75}%) translateY(0px)`,
      }}
    >
      <GatsbyImage
        objectFit="contain"
        objectPosition={"top"}
        image={getImage(props.image)}
        className={classes.projectImage}
        alt={props.altText}
      ></GatsbyImage>
    </div>
  );
}
