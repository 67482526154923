import React from "react";
import * as classes from "./Accordion.module.scss";
import { CaretCircleDown } from "phosphor-react";

export default function Accordion(props) {
  const [openedState, setOpenedState] = React.useState(
    props.firstitem ? true : false
  );
  const [accordionClasses, setAccordionClasses] = React.useState(
    props.firstitem
      ? [classes.container, classes.containerOpened]
      : [classes.container]
  );
  function clicked() {
    setOpenedState(!openedState);
    !openedState
      ? setAccordionClasses([classes.container, classes.containerOpened])
      : setAccordionClasses([classes.container]);
  }
  return (
    <div firstitem={props.firstitem} className={accordionClasses.join(" ")}>
      <div
        className={
          props.firstitem
            ? classes.dateIndicatorContainerCurrent
            : classes.dateIndicatorContainer
        }
      >
        <p className={classes.date}>{props.dates}</p>
        <div className={classes.indicatorContainer}>
          <div className={classes.indicator}></div>
          <div
            className={
              props.lastitem ? classes.connectorLineLast : classes.connectorLine
            }
          ></div>
        </div>
      </div>
      <div
        onClick={clicked}
        onKeyDown={clicked}
        className={classes.accordionContainer}
        role="button"
        aria-label="Expand job details"
        tabIndex={0}
      >
        <div className={classes.accordionHeadingContainer}>
          <h3 className={classes.accordionHeading}>
            <a
              href={props.linkURL}
              target="_blank"
              rel="noreferrer"
              className={classes.largeLinkText}
            >
              {props.linkName}
            </a>
            {props.jobTitle}
          </h3>
          <div className={classes.accordionIcon}>
            <CaretCircleDown size={24} color="#5c85b8" weight="duotone" />
          </div>
        </div>
        <div className={classes.accordionBody}>
          <p>{props.jobDescription}</p>
        </div>
      </div>
    </div>
  );
}
