import React from "react";
import * as classes from "./ControlButton.module.scss";
import { ArrowRight, ArrowLeft } from "phosphor-react";

const ControlButton = (props) => {
  let buttonClasses = [classes.carouselButton];
  let labelString;
  let icon;

  if (props.context === "cards" && props.variant === "left")
    buttonClasses.push(classes.cardsCarouselButtonLeft);
  if (props.context === "cards" && props.variant === "right")
    buttonClasses.push(classes.cardsCarouselButtonRight);
  if (props.context === "images" && props.variant === "left")
    buttonClasses.push(classes.imagesCarouselButtonLeft);
  if (props.context === "images" && props.variant === "right")
    buttonClasses.push(classes.imagesCarouselButtonRight);

  if (props.variant === "left") {
    icon = <ArrowLeft size={18} weight="bold" />;
    labelString = "Previous Item";
  }
  if (props.variant === "right") {
    icon = <ArrowRight size={18} weight="bold" />;
    labelString = "Next Item";
  }
  if (props.disabled) buttonClasses.push(classes.buttonDisabled);

  return (
    <button
      aria-label={labelString}
      className={buttonClasses.join(" ")}
      onClick={props.clicked}
    >
      {icon}
    </button>
  );
};
export default ControlButton;
