import React from "react";
import * as classes from "./ImageCarousel.module.scss";
import Image from "../Image/Image";
// import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/dist/styles.css";

export const ImageCarousel = (props) => {
  return (
    <div className={classes.imageContainer}>
      {props.imageSet.map((_, i) => (
        <Image
          key={i + 1}
          active={props.active === i + 1}
          move={props.move}
          image={props.imageSet[i]}
          type={props.imageSet[i].type || "desktop"}
          altText={props.imageSet[i].title}
        />
      ))}
    </div>
  );
};
